import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const Starter = Loadable(lazy(() => import('../views/Starter')));
const Kontakt = Loadable(lazy(() => import('../views/Kontakt')));
const Impressum = Loadable(lazy(() => import('../views/Impressum')));
const Sicherheitslaeuchten = Loadable(lazy(() => import('../views/Sicherheitslaeuchten')));
const ChemischeProdukte = Loadable(lazy(() => import('../views/Chemischeprodukte')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
/*****Routes******/

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/home" /> },
      { path: '/datenschutz', name: 'Home', element: <Navigate to="/home" /> },
      { path: '/home', name: 'Home', exact: true, element: <Starter /> },
      { path: '/kontakt', name: 'Kontakt', exact: true, element: <Kontakt /> },
      {
        path: '/sichheitslaeuchten',
        name: 'Sichheitslaeuchten',
        exact: true,
        element: <Sicherheitslaeuchten />,
      },
      {
        path: '/chemischeprodukte',
        name: 'Chemische Produkte',
        exact: true,
        element: <ChemischeProdukte />,
      },
      { path: '/impressum', name: 'Impressum', exact: true, element: <Impressum /> },

      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default ThemeRoutes;
