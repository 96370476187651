import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import ArtikelReducer from './apps/artikel/ArtikelSlice';
import ChemischeProduckteReducer from './apps/artikel/ChemischeProduckteSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    artikelReducer: ArtikelReducer,
    chemischeProduckteReducer: ChemischeProduckteReducer,
  },
});

export default store;
