import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const API_URL = '/api/data/ticket/Data';

const initialState = {
  artikels: [],
};

export const ChemischeProduckteSlice = createSlice({
  name: 'artikel',
  initialState,
  reducers: {
    getArtikels: (state, action) => {
      state.artikels = action.payload;
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
  },
});

export const { getArtikels } = ChemischeProduckteSlice.actions;

export const FetchArtikels = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}`);
    const data = response.data.filter((x) => x.hidden === false);
    dispatch(getArtikels(data));
  } catch (err) {
    throw new Error(err);
  }
};

export default ChemischeProduckteSlice.reducer;
