import mock from '../mock';

import imgPld25 from '../../assets/images/products/artikels/notstrom/PLD-25.jpg';
import imgPld25a from '../../assets/images/products/artikels/notstrom/PLD-25a.jpg';
import imgZld28 from '../../assets/images/products/artikels/notstrom/ZLD-28.jpg';
import imgZld28a from '../../assets/images/products/artikels/notstrom/ZLD-28a.jpg';
import imgKld22 from '../../assets/images/products/artikels/notstrom/KLD-22.jpg';
import imgKld22a from '../../assets/images/products/artikels/notstrom/KLD-22a.jpg';
import imgMld22 from '../../assets/images/products/artikels/notstrom/MLD-28.jpg';
import imgMld22a from '../../assets/images/products/artikels/notstrom/MLD-28a.jpg';
import imgKlr20 from '../../assets/images/products/artikels/notstrom/KLR-20.jpg';
import imgKlr20a from '../../assets/images/products/artikels/notstrom/KLR-20a.jpg';
import imgSld28 from '../../assets/images/products/artikels/notstrom/SLD-28.jpg';
import imgSld28a from '../../assets/images/products/artikels/notstrom/SLD-28a.jpg';
import imgGr939 from '../../assets/images/products/artikels/notstrom/GR-939.jpg';
import imgGr939a from '../../assets/images/products/artikels/notstrom/GR-939a.jpg';
import imgGr316 from '../../assets/images/products/artikels/notstrom/GR-316.jpg';
import imgGr316a from '../../assets/images/products/artikels/notstrom/GR-316a.jpg';

const NotstromArtikelData = [
  {
    Id: 1,
    artikelNumber: 'PLD-25',
    artikelTitle: 'PLD-25/NiMH',
    ip: '44',
    akku: '3,6V/1,2Ah Νi-Mh,',
    autonomie: '3h',
    leistungsaufnahme: '4W/4,4 VA, Lumen: 90/100 lm',
    gruppe: 'Eco-Light',
    label: 'warning',
    image: imgPld25,
    abmessung: imgPld25a,
    hidden: false,
  },
  {
    Id: 2,
    artikelNumber: 'ZLD-28',
    artikelTitle: 'ZLD-28/EM',
    ip: '40',
    akku: '3,6V/1,5Ah Νi-Mh,',
    autonomie: '3h',
    leistungsaufnahme: '3,8W/6,5VA, Lumen: 125/125 lm',
    gruppe: 'Eco-Light',
    label: 'warning',
    image: imgZld28,
    abmessung: imgZld28a,
    hidden: false,
  },
  {
    Id: 3,
    artikelNumber: 'KLD-22',
    artikelTitle: 'KLD-22/ST',
    ip: '54',
    akku: '3,6V/1,5Ah Ni-Mh,',
    autonomie: '1-3-8 h',
    leistungsaufnahme: '3,9W/4,4VA, Lumen: 80/250 80/110 80/15 lm',
    gruppe: 'Eco-Light',
    label: 'warning',
    image: imgKld22,
    abmessung: imgKld22a,
    hidden: false,
  },
  {
    Id: 4,
    artikelNumber: 'MLD-28',
    artikelTitle: 'MLD-28/DW',
    ip: '40',
    akku: '3,6V/1Ah',
    autonomie: '3h',
    leistungsaufnahme: '3W/7,5 VA, Lumen: 85 lm',
    gruppe: 'Eco-Light',
    label: 'warning',
    image: imgMld22,
    abmessung: imgMld22a,
    hidden: false,
  },
  {
    Id: 5,
    artikelNumber: 'KLR-20',
    artikelTitle: 'KLR-20/ST',
    ip: '54',
    akku: '3,6V/1,5Ah Ni-Mh',
    autonomie: '1-3-8 h',
    leistungsaufnahme: '3,5W/4VA, Lumen: 80/250 80/110 80/15 lm',
    gruppe: 'Eco-Light',
    label: 'warning',
    image: imgKlr20,
    abmessung: imgKlr20a,
    hidden: false,
  },
  {
    Id: 6,
    artikelNumber: 'SLD-28',
    artikelTitle: 'SLD-28/SP',
    ip: '30',
    akku: '3,6V/1,5Ah Νi-Mh',
    autonomie: '3h',
    leistungsaufnahme: '9VA, Lumen: 100/100 lm',
    gruppe: 'Lounge-Light',
    label: 'success',
    image: imgSld28,
    abmessung: imgSld28a,
    hidden: false,
  },
  {
    Id: 7,
    artikelNumber: 'GR-939',
    artikelTitle: 'GR-939/30L',
    ip: '65',
    akku: '3,6V/3Ah',
    autonomie: '3h',
    leistungsaufnahme: '5,3W/5,6 VA, Lumen: 210 lm',
    gruppe: 'Weather-Light',
    label: 'danger',
    image: imgGr939,
    abmessung: imgGr939a,
    hidden: false,
  },
  {
    Id: 8,
    artikelNumber: 'GR-900',
    artikelTitle: 'GR-900/30L/SC',
    ip: '65',
    akku: 'Superkondensatoren',
    autonomie: '1h',
    leistungsaufnahme: '6W/6,5VA/PF:0.92, Lumen: 250/90 lm',
    gruppe: 'Weather-Light',
    label: 'danger',
    image: imgGr939,
    abmessung: imgGr939a,
    hidden: false,
  },
  {
    Id: 9,
    artikelNumber: 'GR-316',
    artikelTitle: 'GR-316/4P',
    ip: '40',
    akku: '4,8V/3Ah',
    autonomie: '3h',
    leistungsaufnahme: '5,5W/13,5 VA, Lumen: 250 lm',
    gruppe: 'Easy-Light',
    label: 'success',
    image: imgGr316,
    abmessung: imgGr316a,
    hidden: false,
  },
  {
    Id: 10,
    artikelNumber: 'GR-1316',
    artikelTitle: 'GR-1316/30L',
    ip: '40',
    akku: '3,6V/3Ah Ni-Cd',
    autonomie: '3h',
    leistungsaufnahme: '5,3W/5,6VA, Lumen: 210/210 lm',
    gruppe: 'Easy-Light',
    label: 'success',
    image: imgGr316,
    abmessung: imgGr316a,
    hidden: false,
  },
  {
    Id: 11,
    artikelNumber: 'GR-308',
    artikelTitle: 'GR-308/15L/8H',
    ip: '40',
    akku: '3,6V/3Ah',
    autonomie: '3h',
    leistungsaufnahme: '3,7W/4VA, Lumen: 125 lm',
    gruppe: 'Easy-Light',
    label: 'success',
    image: imgGr316,
    abmessung: imgGr316a,
    hidden: false,
  },
];

mock.onGet('/api/data/ticket/TicketData').reply(() => {
  return [200, NotstromArtikelData];
});
export default NotstromArtikelData;
