import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';

const API_URL = '/api/data/ticket/TicketData';

const initialState = {
  artikels: [],
  currentFilter: 'alle_artikeln',
  artikelSearch: '',
};

export const ArtikelSlice = createSlice({
  name: 'artikel',
  initialState,
  reducers: {
    getArtikels: (state, action) => {
      state.artikels = action.payload;
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
    SearchArtikel: (state, action) => {
      state.artikelSearch = action.payload;
    },
    DeleteArtikel: (state, action) => {
      const index = state.artikels.findIndex((artikel) => artikel.Id === action.payload);
      state.artikels.splice(index, 1);
    },
  },
});

export const { getArtikels, setVisibilityFilter, SearchArtikel, DeleteArtikel } =
  ArtikelSlice.actions;

export const FetchArtikels = () => async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}`);
    dispatch(getArtikels(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export default ArtikelSlice.reducer;
