import mock from '../mock';

import imgCh1 from '../../assets/images/images.png';

const ChemischeprodukteArtikelData = [
  {
    Id: 1,
    artikelNumber: 'PA 6.6 GF30%',
    artikelTitle: 'PA 6.6 GF30%',
    artikelDescription: 'PA 6.6 GF30% PreaMid A GF30 /A/550 natur',
    gruppe: '4,12 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 2,
    artikelNumber: 'PA 6.6 PreaMid',
    artikelTitle: 'PA 6.6 PreaMid',
    artikelDescription: 'PA 6.6 PreaMid A  /A/550 natur',
    gruppe: '4,32 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 3,
    artikelNumber: 'PC/ABS PreBlend',
    artikelTitle: 'PC/ABS PreBlend',
    artikelDescription: 'PC/ABS PreBlend /A/145 natur T85',
    gruppe: '3,26 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 4,
    artikelNumber: 'PA 6 PreaMid',
    artikelTitle: 'PA 6 PreaMid',
    artikelDescription: 'PA 6 PreaMid B /B/550 natur',
    gruppe: '3,09 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 5,
    artikelNumber: 'PA 6 GF 30%',
    artikelTitle: 'PA 6 GF 30%',
    artikelDescription: 'PA 6 GF 30% PreaMid B /B/550 natur',
    gruppe: '3,09 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 6,
    artikelNumber: 'PA/ABS PreBlend',
    artikelTitle: 'PA/ABS PreBlend',
    artikelDescription: 'PA/ABS PreBlend ALS(UV) /A/126 natur',
    gruppe: '3,99 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 7,
    artikelNumber: 'POM MFI9 Formocon',
    artikelTitle: 'POM MFI9 Formocon',
    artikelDescription: 'POM MFI9 Formocon FM090 natur',
    gruppe: '3,29 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 8,
    artikelNumber: 'D42 M1-012',
    artikelTitle: 'D42 M1-012',
    artikelDescription: 'Weiche-PVC 51 A2 88 D42 M1-012',
    gruppe: '3,090 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 9,
    artikelNumber: 'D46 M1-012',
    artikelTitle: 'D46 M1-012',
    artikelDescription: 'Weiche-PVC 51 A2 88 D46 M1-012',
    gruppe: '3,020 Eur/kg',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
  {
    Id: 10,
    artikelNumber: 'D48 M1-012',
    artikelTitle: 'D48 M1-012',
    artikelDescription: 'Weiche-PVC 51 A2 88 D48 M1-012',
    gruppe: '2,890 Eur/kg ',
    label: 'warning',
    image: imgCh1,
    hidden: false,
  },
];

mock.onGet('/api/data/ticket/Data').reply(() => {
  return [200, ChemischeprodukteArtikelData];
});
export default ChemischeprodukteArtikelData;
